<template>
  <div>
    <v-container class="first pa-0 mt-6 position-relative"
                 :style="{
                    height: windowWidth > 1263
                      ? 'calc(100vh - 90px)'
                      : windowWidth > 959
                          ? '550px'
                          : windowWidth > 700
                              ? '450px'
                              : windowWidth > 599
                                  ? '420px'
                                  : windowWidth > 400
                                    ? '460px'
                                    : '400px'
                 }">
      <v-row class="ma-0">
        <v-col cols="10" offset="1" offset-md="0" xl="5" lg="6" md="8"
               class="pb-0 mt-xl-16 mt-lg-14 mt-md-12 z-index-3">
          <v-img :width="windowWidth > 1263 ? '80%' : windowWidth > 599 ? '60%' : '100%'"
                 :src="require('@/assets/images/maincompanyname.svg')" contain></v-img>
          <p class="first-subtitle mt-lg-8 mt-md-6 mt-3">Данный сайт является официальным сайтом<br
            v-if="windowWidth > 599"> команды Atomy-Grace</p>
          <div class="first-btn mt-xl-14 mt-lg-10 mt-md-6 mt-0 pt-2 pt-sm-0 mt-sm-8">
            <v-btn @click="$router.push({name: 'business', hash: '#advantages'})"
                   class="button px-lg-8 px-md-6 py-lg-7 py-md-6 py-6 px-6" color="#018ABE">Преимущества
            </v-btn>
          </div>
          <div class="d-sm-block d-flex justify-center">
            <ol class="first-list">
              <li><a href="#">Бесплатная регистрация</a></li>
              <li><a href="#">Нет стартовых пакетов</a></li>
              <li><a href="#">Нет обязательных закупок</a></li>
              <li><a href="#">Нет членских взносов</a></li>
              <li><a href="#">И другие привелегии</a></li>
            </ol>
          </div>
        </v-col>
      </v-row>
      <div class="first-bg">
        <div class="first-bg__wrapper">
          <img :src="require('@/assets/images/bg1main.png')" class="first-bg__image" alt="bg"/>
        </div>
        <v-img :src="require('@/assets/images/tableeffect.png')" class="first-table-effect"></v-img>
      </div>
    </v-container>
    <v-container fluid class="second pa-0 mt-md-15 mt-7 position-relative">
      <v-row class="ma-0">
        <v-col cols="12" ref="secondBlock" class="pb-0 second-block px-0">
          <v-img :src="require('@/assets/images/bg2main.jpg')" class="second-wrapper" cover v-if="windowWidth > 599">
            <v-container class="pa-0 second-triangles__buttons" style="height: 80%">
              <v-row class="ma-0">
                <v-col cols="12" md="6" class="pt-md-10">
                  <h2 class="second-production__title mt-md-15">Продукция</h2>
                  <p class="second-production__subtitle">Абсолютное качество — абсолютная цена:<br>абсолютная ценность
                    Atomy</p>
                  <v-btn @click="$router.push({name: 'products'})" class="button px-md-12 py-md-5 px-12" color="#018ABE"
                         dark>Перейти
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6" class="d-flex flex-column justify-end pb-md-10 second-business__block">
                  <h2 class="second-business__title">Бизнес</h2>
                  <p class="second-business__subtitle">Узнайте о возможности официального<br>пассивного заработка</p>
                  <v-btn @click="$router.push({name: 'business'})" class="button px-md-12 py-md-5 px-12 align-self-end"
                         color="#FFFFFF">Перейти
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <svg class="second-triangles__hover" width="100%" viewBox="0 0 1375 637" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path @click="$router.push({name: 'products'})" @mouseover="whiteActive = true; blueActive = false"
                    @mouseout="whiteActive = false"
                    class="second-triangle__white-hover" d="M0 637V0H1375L0 637Z" fill="#FFFFFF" fill-opacity="0"/>
              <path @click="$router.push({name: 'business'})" @mouseover="blueActive = true; whiteActive = false"
                    @mouseout="blueActive = false"
                    class="second-triangle__blue-hover" d="M1375 637V0L0 637H1375Z" fill="#FFFFFF" fill-opacity="0"/>
            </svg>
            <svg class="second-triangles__colors" width="100%" viewBox="0 0 1375 637" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path class="second-triangle__white" d="M0 637V0H1375L0 637Z" fill="#FFFFFF" fill-opacity="0"/>
              <path class="second-triangle__blue" d="M1375 637V0L0 637H1375Z" fill="#109BD6" fill-opacity="0.6"/>
            </svg>
            <div class="second-triangles__bottom">
              <v-container class="pa-0 second-triangles__register">
                <v-row class="ma-0">
                  <v-col cols="12">
                    <div class="d-flex justify-space-between">
                      <h2 class="second-register__title">
                        Регистрация и предоставление доступа<br>
                        в личный кабинет интернет-магазина Атоми
                      </h2>
                      <v-btn @click="$store.dispatch('form/toggleDialog')"
                             class="second-register__button button py-lg-7 py-md-6 px-lg-16 px-md-12 px-8"
                             color="#FFA000">Регистрация
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-img>
          <v-row class="ma-0 second-mobile__background" v-else>
            <v-col cols="12">
              <h2 class="second-production__title">Продукция</h2>
              <p class="second-production__subtitle">Абсолютное качество — абсолютная цена:<br>абсолютная ценность Atomy
              </p>
              <v-btn @click="$router.push({name: 'products'})" v-if="windowWidth < 1264" class="button px-12"
                     color="#018ABE" dark>Перейти
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex flex-column justify-end second-business__block mt-12">
              <h2 class="second-business__title">Бизнес</h2>
              <p class="second-business__subtitle">Узнайте о возможности официального<br>пассивного заработка</p>
              <v-btn @click="$router.push({name: 'business'})" v-if="windowWidth < 1264"
                     class="button px-12 align-self-end" color="#018ABE" dark>Перейти
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="ma-0 mt-7" v-if="windowWidth < 600">

            <v-col cols="12">
              <div class="d-flex flex-column align-center">
                <h2 class="second-register__title text-center">
                  Регистрация и предоставление доступа<br>
                  в личный кабинет интернет-магазина Атоми
                </h2>
                <v-btn width="60%" @click="$store.dispatch('form/toggleDialog')"
                       class="second-register__button button mt-3" color="#FFA000">Регистрация
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="third mt-md-15 mt-7 position-relative">
      <v-row>
        <v-col cols="12">
          <div class="third-wrapper">
            <span class="third-text">
              «Atomy» - глобальная компания, действующая как глобальный интернет-магазин клубного типа со множеством
              филиалов в разных странах мира.
            </span>
            <br>
            <span class="third-text">
              Философия компании «Абсолютное качество по Абсолютной цене». Это означает, что компания реализует
              продукцию максимально премиального качества и устанавливает максимально низкие цены, которые только возможны без ущерба для качества.

            </span>
            <br class="d-lg-none d-md-block">
            <span class="third-text d-lg-none d-md-inline">
              Именно эта ценность
              стоит во главе угла с самого основания Atomy. Главными ценностями нашей компании являются ЧЕСТНОСТЬ,
              ДОБРОТА и УСПЕХ КЛИЕНТА.
            </span>
            <div class="third-video__wrapper" v-if="windowWidth > 959">
              <div class="third-video__movie">
                <vue-plyr>
                  <video playsinline crossorigin controls width="100%"
                         :poster="require('@/assets/images/main-video__preview.jpg')">
                    <source :src="getVideo()" type="video/mp4"/>
                  </video>
                </vue-plyr>
              </div>
              <div class="third-video__subscribe mt-lg-5 mt-sm-3">
                <v-btn @click="linkToYoutube()"
                       class="third-video__subscribe-button button px-lg-15 px-md-12 py-lg-7 py-md-6" color="#018ABE">
                  Перейти на канал
                </v-btn>
                <img @click="linkToYoutube()" class="third-video__subscribe-icon ml-3"
                     :src="require('@/assets/icons/youtube.svg')"
                     alt="youtube-icon"/>
              </div>
            </div>
            <br class="d-lg-inline d-none">
            <span class="third-text d-lg-inline d-none">
              Именно эта ценность
              стоит во главе угла с самого основания Atomy. Главными ценностями нашей компании являются ЧЕСТНОСТЬ,
              ДОБРОТА и УСПЕХ КЛИЕНТА.
            </span>
            <br>
            <span class="third-text">
              «Atomy» нужна каждому, так как позволяет существенно улучшить качество жизни каждого человека.
            </span>
            <br>
            <span class="third-text">
              «Atomy» рассматривает человека не как средство достижения прибыли, но как наивысшую ценность, поэтому
              девиз компании звучит:
            </span>
            <br><br class="d-xl-inline d-none">
            <p class="third-text__motto">
              «Заботиться о душе, <br>
              создавать видение будущего, <br>
              следовать вере, <br>
              служить в смирении».
            </p>
          </div>
        </v-col>
        <v-col cols="12" v-if="windowWidth < 960" class="pt-0">

          <div class="third-video__movie">
            <vue-plyr>
              <video playsinline crossorigin controls width="100%"
                     :poster="require('@/assets/images/main-video__preview.jpg')">
                <source :src="getVideo()" type="video/mp4"/>
              </video>
            </vue-plyr>
          </div>
          <div class="third-video__subscribe mt-lg-5">
            <v-btn @click="linkToYoutube()"
                   class="third-video__subscribe-button button px-lg-15 px-md-12 py-lg-7 py-md-6" color="#018ABE">
              Перейти на канал
            </v-btn>
            <img @click="linkToYoutube()" class="third-video__subscribe-icon ml-3"
                 :src="require('@/assets/icons/youtube.svg')"
                 alt="youtube-icon"/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
    props: ['windowWidth'],
    data() {
        return {
            whiteActive: false,
            blueActive: false
        }
    },
    created() {
        window.addEventListener('scroll', this.getHeightForTriangles)
    },
    destroyed() {
        window.removeEventListener('scroll', this.getHeightForTriangles)
    },
    methods: {
        getVideo() {
            return process.env.VUE_APP_API_URL + '/api/image/getVideo/' + 'main-video.mp4'
        },
        getHeightForTriangles() {
            let height = {}
            height.image = document.querySelector('.second-wrapper').getBoundingClientRect().height
            height.triangles = document.querySelector('.second-triangles__colors').getBoundingClientRect().height
            let secondTrianglesBottomHeight = height.image - height.triangles + 'px'
            document.querySelector('.second-triangles__bottom').style.height = secondTrianglesBottomHeight
            document.querySelector('.second-triangles__bottom').style.opacity = 1
            document.querySelector('.second-triangles__buttons').style.height = `calc(100% - ${secondTrianglesBottomHeight})`
            document.querySelector('.second-triangles__register').style.height = secondTrianglesBottomHeight
        },
        changeWhiteTriangle(status) {
            let whiteTriangle = document.querySelector('.second-triangle__white')
            let blueTriangle = document.querySelector('.second-triangle__blue')
            let businessTitle = document.querySelector('.second-business__title')
            let businessSubtitle = document.querySelector('.second-business__subtitle')
            if (status === true) {
                whiteTriangle.classList.add('second-triangle__white-active')
                blueTriangle.classList.add('second-triangle__blue-inactive')
                businessTitle.style.color = 'rgba(16, 155, 214, 0.6)'
                businessSubtitle.style.color = 'rgba(16, 155, 214, 0.6)'
            } else {
                whiteTriangle.classList.remove('second-triangle__white-active')
                blueTriangle.classList.remove('second-triangle__blue-inactive')
                businessTitle.style.color = '#FFFFFF'
                businessSubtitle.style.color = '#FFFFFF'
            }
        },
        changeBlueTriangle(status) {
            let blueTriangle = document.querySelector('.second-triangle__blue')
            let productionTitle = document.querySelector('.second-production__title')
            let productionSubtitle = document.querySelector('.second-production__subtitle')
            if (status === true) {
                blueTriangle.classList.add('second-triangle__blue-active')
                blueTriangle.classList.remove('second-triangle__blue-inactive')
                productionTitle.style.color = '#FFFFFF'
                productionSubtitle.style.color = '#FFFFFF'
            } else {
                blueTriangle.classList.remove('second-triangle__blue-active')
                productionTitle.style.color = 'rgba(16, 155, 214, 0.6)'
                productionSubtitle.style.color = 'rgba(16, 155, 214, 0.6)'
            }
        },
        linkToYoutube() {
            window.open('https://youtube.com/@Atomy-Grace2023', '_blank');
        }
    },
    watch: {
        whiteActive(newValue) {
            if (this.windowWidth > 1263) {
                this.changeWhiteTriangle(newValue)
            }
        },
        blueActive(newValue) {
            if (this.windowWidth > 1263) {
                this.changeBlueTriangle(newValue)
            }
        },
        windowWidth() {
            this.getHeightForTriangles()
        },
        image() {
            this.getHeightForTriangles()
        }
    }
}
</script>

<style scoped lang="sass">
.first
  &-subtitle
    width: 80%
    font-size: 20px
    text-align: start
    //font-family: "Atomy Light"
    font-family: 'Roboto', sans-serif
    font-weight: normal

  &-btn
    display: flex
    align-self: center

    .v-btn
      color: #ffffff
      font-size: 20px

  &-list
    margin-top: 24px
    counter-reset: li
    list-style: none
    font: 14px "Roboto", sans-serif
    padding: 0
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5)

    a
      position: relative
      display: block
      padding: 6px 6px 6px 26px
      margin-bottom: 4px
      margin-left: 14px
      background: linear-gradient(90deg, rgb(1, 138, 190) 0%, rgba(255, 255, 255, 0) 60%)
      color: #ffffff
      text-decoration: none
      border-radius: .3em
      transition: .3s ease-out

      &:before
        content: counter(li)
        counter-increment: li
        position: absolute
        left: -1.3em
        top: 50%
        margin-top: -1.3em
        background: #FFA000
        height: 36px
        width: 36px
        line-height: 2em
        border: .3em solid white
        text-align: center
        font-weight: bold
        border-radius: 2em
        transition: all .3s ease-out


  &-image__wrapper
    width: 70%
    height: auto
    position: relative
    margin-left: auto

  &-bg
    position: absolute
    height: 100%
    width: 100%
    bottom: 0

    &__wrapper
      position: absolute
      right: 0
      width: 70%
      height: 100%

    &__image
      position: absolute
      right: 0
      bottom: 0
      height: auto
      width: 100%
      z-index: 2

  &-table-effect
    position: absolute
    bottom: 0
    left: 50%
    transform: translateX(-50%)
    width: 100vw
    max-width: 100vw
    height: 145px
    z-index: 1

.second
  &-production
    &__title
      color: rgba(16, 155, 214, 0.6)
      font-size: 36px
      transition: 1s color
      //font-family: "Atomy Bold"
      font-family: 'Roboto', sans-serif
      font-weight: bold

    &__subtitle
      color: rgba(16, 155, 214, 0.6)
      font-size: 24px
      transition: 1s color
      //font-family: "Atomy Light"
      font-family: 'Roboto', sans-serif
      font-weight: normal

  &-business
    &__title
      color: #FFFFFF
      width: 100%
      text-align: end
      font-size: 36px
      transition: 1s color
      //font-family: "Atomy Bold"
      font-family: 'Roboto', sans-serif
      font-weight: bold

    &__subtitle
      color: #FFFFFF
      width: 100%
      text-align: end
      font-size: 24px
      transition: 1s color
      //font-family: "Atomy Light"
      font-family: 'Roboto', sans-serif
      font-weight: normal

  &-register
    &__title
      //font-family: "Atomy Bold"
      font-family: 'Roboto', sans-serif
      font-weight: bold
      font-size: 24px
      color: #FFFFFF

    &__button
      color: #FFFFFF
      text-transform: none
      font-size: 20px
      border-radius: 8px

  &-wrapper
    cursor: pointer
    height: 100%
    width: 100%
    position: relative

  &-triangles
    &__buttons
      position: absolute
      left: 50%
      transform: translateX(-50%)

      .row, .col
        height: 100%

    &__register
      z-index: 100
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)

      div
        height: 100%
        align-items: center

      .row, .col
        height: 100%

    &__hover
      position: absolute
      top: 0
      z-index: 10

    &__bottom
      transition: 0.3s all
      position: absolute
      bottom: 0
      opacity: 0
      width: 100%
      background-color: rgba(16, 155, 214, 0.6)

  &-triangle
    &__white
      transition: 1s transform
      z-index: 10

      &-active
        transform: scale(2)

    &__blue
      transition: 1.5s all
      transform-origin: 100% 100%

      &-active
        transform: scale(2) translateY(2px)

      &-inactive
        transform: scale(0)

.third
  &-wrapper
    text-align: justify

  &-text
    margin-left: 34px
    font-size: 20px
    //font-family: "Atomy Light"
    font-family: 'Roboto', sans-serif
    font-weight: normal

    &__motto
      text-align: center
      font-size: 20px
      //font-family: "Atomy Medium"
      font-family: 'Roboto', sans-serif
      font-weight: normal

  &-video
    &__wrapper
      margin-top: 8px
      margin-left: 16px
      position: relative
      float: right
      width: 45%

    &__movie
      width: 100%
      position: relative

    &__subscribe
      display: flex
      justify-content: center

      &-button
        font-size: 20px
        color: #ffffff

      &-icon
        height: 50px
        align-self: center
        cursor: pointer

@media (max-width: 1263.99px)
  .second
    &-production
      &__title
        font-size: 28px

      &__subtitle
        font-size: 20px

    &-business
      &__title
        font-size: 28px

      &__subtitle
        font-size: 20px

    &-register
      &__title
        font-size: 20px

      &__button
        font-size: 18px
  .third
    &-text
      font-size: 18px

      &__motto
        font-size: 18px

    &-video
      &__wrapper
        width: 50%

      &__movie
        height: 270px

      &__subscribe
        &-button
          font-size: 18px

        &-icon
          height: 40px

@media (max-width: 959.98px)
  .first
    &-subtitle
      width: 80%
      font-size: 18px

    &-btn
      display: flex
      align-self: center

      .v-btn
        color: #ffffff
        font-size: 16px

    &-list
      margin-top: 16px
      font: 12px "Roboto", sans-serif

      a
        padding: 3px 3px 3px 26px
        margin-left: 12px

        &:before
          line-height: 1.7em
          margin-top: -14px
          height: 26px
          width: 26px

    &-bg
      &__wrapper
        width: 65%

    &-table-effect
      height: 100px
  .second
    &-production
      &__title
        font-size: 24px

      &__subtitle
        font-size: 18px
        margin-bottom: 8px

    &-business
      &__block
        position: absolute
        bottom: 0

      &__title
        font-size: 24px

      &__subtitle
        font-size: 18px
        margin-bottom: 8px

    &-register
      &__title
        font-size: 16px

      &__button
        font-size: 16px
  .third
    &-text
      margin-left: 0
      font-size: 16px

      &__motto
        margin-top: 12px
        font-size: 16px

    &-video
      &__wrapper
        margin-top: 0
        margin-left: 0
        width: 100%

      &__movie
        height: auto
        width: 100%
        position: relative

      &__subscribe
        margin-top: 12px
        align-items: center

        &-button
          font-size: 16px

        &-icon
          height: 40px

@media (max-width: 700px)
  .second
    &-production
      &__subtitle
        font-size: 16px

    &-business
      &__subtitle
        font-size: 16px

    &-register
      &__title
        font-size: 14px

      &__button
        font-size: 14px

@media (max-width: 599.98px)
  .first
    &-subtitle
      width: 100%
      font-size: 16px
      text-align: center

    &-btn
      justify-content: center


    &-list
      width: 230px

      a
        background: linear-gradient(90deg, rgb(1, 138, 190) 60%, rgba(255, 255, 255, 0) 94%)

      li
        &:nth-child(2n)
          a
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(1, 138, 190) 40%)
            text-align: right
            padding: 3px 36px 3px 3px

          a:before
            display: none

          a:after
            content: counter(li)
            counter-increment: li
            position: absolute
            right: -5px
            top: 50%
            margin-top: -14px
            background: #FFA000
            height: 26px
            width: 26px
            line-height: 1.7em
            border: .3em solid white
            text-align: center
            font-weight: bold
            border-radius: 2em
            transition: all .3s ease-out

    &-bg
      opacity: 0.8

      &__wrapper
        width: 100%

      &__image
        bottom: 118px

    &-table-effect
      height: 100px
      bottom: 118px
  .second
    &-mobile__background
      background: url("../assets/images/bg2main.jpg") center
      background-size: cover

    &-production
      &__title
        font-size: 22px

      &__subtitle
        font-size: 16px
        margin-bottom: 8px

    &-business
      &__block
        position: relative
        bottom: 0

      &__title
        color: black
        font-size: 22px

      &__subtitle
        color: black
        font-size: 16px
        margin-bottom: 8px

    &-register
      &__title
        font-size: 16px
        color: black

      &__button
        font-size: 16px
</style>
